/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    br: "br",
    ol: "ol",
    strong: "strong"
  }, _provideComponents(), props.components), {Specialization, Condition, Skill, Attribute, Item, Trait, Boon, Beginner, Information, Divider, Grid, GridItem, Traits, Advanced, Card, Effect, Video, BuildLink} = _components;
  if (!Advanced) _missingMdxReference("Advanced", true);
  if (!Attribute) _missingMdxReference("Attribute", true);
  if (!Beginner) _missingMdxReference("Beginner", true);
  if (!Boon) _missingMdxReference("Boon", true);
  if (!BuildLink) _missingMdxReference("BuildLink", true);
  if (!Card) _missingMdxReference("Card", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Divider) _missingMdxReference("Divider", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!Grid) _missingMdxReference("Grid", true);
  if (!GridItem) _missingMdxReference("GridItem", true);
  if (!Information) _missingMdxReference("Information", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Traits) _missingMdxReference("Traits", true);
  if (!Video) _missingMdxReference("Video", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(Specialization, {
    name: "Willbender",
    text: "Power Willbender"
  }), " is an easy to play build for fractals. It has many similarities with the ", React.createElement(Specialization, {
    text: "Power Dragonhunter",
    name: "Dragonhunter"
  }), ". It has great burst options and deals high consistent damage while providing medium defiance bar damage and ", React.createElement(Condition, {
    name: "Vulnerability"
  }), ". The build offers a unique party wide offensive buff by sharing ", React.createElement(Skill, {
    name: "bane signet",
    id: "9093"
  }), " for 216 ", React.createElement(Attribute, {
    name: "Power"
  }), " and also strong on-demand defensive support with skills such as ", React.createElement(Skill, {
    id: "9251",
    id: "9251"
  }), " and ", React.createElement(Skill, {
    name: "standyourground",
    id: "9153"
  }), "."), "\n", React.createElement(_components.p, null, "The ", React.createElement(Specialization, {
    text: "Power Willbender",
    name: "Willbender"
  }), " has access to blocks with ", React.createElement(Skill, {
    name: "Shield of Wrath",
    id: "9082"
  }), ", ", React.createElement(Skill, {
    name: "Crashing Courage",
    id: "62532"
  }), " and a very strong heal skill (", React.createElement(Skill, {
    name: "Litany of Wrath",
    id: "21664"
  }), "). It benefits from slaying potions and slaying sigils such as ", React.createElement(Item, {
    id: "50082",
    id: "50082"
  }), " and ", React.createElement(Item, {
    name: "Serpent Slaying",
    type: "Sigil",
    id: "24658"
  }), "."), "\n", React.createElement(_components.p, null, "While this build has less burst damage than the ", React.createElement(Specialization, {
    text: "Power Dragonhunter",
    name: "Dragonhunter"
  }), ", it comes with some other nice benefits:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Swapping out ", React.createElement(Skill, {
    name: "Whirling Light",
    id: "62565"
  }), " for another utility to support your group comes at almost no DPS loss."), "\n", React.createElement(_components.li, null, "If boons are lacking in your group, you can take 2 optional traits (", React.createElement(Trait, {
    name: "Zealous Scepter",
    id: "1925"
  }), " and ", React.createElement(Trait, {
    name: "Holy Reckoning",
    id: "2210"
  }), ") to ensure permanent ", React.createElement(Boon, {
    name: "Might"
  }), " uptime on yourself."), "\n", React.createElement(_components.li, null, "The Elite skill ", React.createElement(Skill, {
    name: "Heavens Palm",
    id: "62561"
  }), " provides great extra CC on top of ", React.createElement(Skill, {
    name: "Bane Signet",
    id: "9093"
  }), "."), "\n"), "\n", React.createElement(Beginner, null, React.createElement(_components.p, null, "Before progressing to the advanced version of this page, we recommend acquiring an in-depth understanding of your CC skills and when to use them efficiently. You should be able to hit consistently high DPS, which you can verify with ", React.createElement(_components.a, {
    href: "https://www.deltaconnected.com/arcdps/"
  }, "arc-dps"), ". And finally, you should be able to execute the basic step-by-step rotation provided below without spending too much thought on it."), React.createElement(_components.p, null, "The advanced page provides a more complete overview of all the utilities the ", React.createElement(Specialization, {
    name: "Guardian"
  }), " has to offer. If you ever feel like you are missing information feel free to already swap to the advanced page!"), React.createElement(Information, null, React.createElement(_components.p, null, "Although this build lists 162 ", React.createElement(Attribute, {
    name: "Agony Resistance"
  }), ", it is not a strict requirement! We recommend getting started with this build anyway - even if it might be suboptimal while being in Tier 1 or 2 fractals. Keep upgrading your ", React.createElement(Attribute, {
    name: "Agony Resistance"
  }), " until you reach the suggested variant. In the beginning, it is much more important to learn about fractal mechanics and your skills rather than equipping optimal gear."))), "\n", React.createElement(Divider, {
    text: "Equipment"
  }), "\n", React.createElement(Divider, {
    text: "Build"
  }), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "7"
  }, React.createElement(Traits, {
    traits1Id: "16",
    traits1: "Radiance",
    traits1SelectedIds: "566,565,579",
    traits2Id: "42",
    traits2: "Zeal",
    traits2SelectedIds: "634,653,2017",
    traits3Id: "65",
    traits3: "Willbender",
    traits3SelectedIds: "2190,2197,2201",
    traits1Id: "16",
    traits1SelectedIds: "566,565,579",
    traits2Id: "42",
    traits2SelectedIds: "634,653,2017",
    traits3Id: "65",
    traits3SelectedIds: "2190,2197,2201"
  }), React.createElement(Advanced, null, React.createElement(Card, {
    title: "Virtues"
  }, React.createElement(_components.p, null, "If you can reliably upkeep ", React.createElement(Boon, {
    name: "Aegis"
  }), ", and you don't gain value from sharing ", React.createElement(Skill, {
    name: "Bane Signet",
    id: "9093"
  }), " through ", React.createElement(Trait, {
    name: "Perfect Inscriptions",
    id: "579"
  }), ". You can drop the Radiance trait line for Virtues for more personal damage. Please note you will need to adjust your gear potentially to crit cap! Check the ", React.createElement(_components.a, {
    href: "https://optimizer.discretize.eu"
  }, "gear optimizer"), " for more gear variants!"), React.createElement(Traits, {
    unembossed: true,
    traits1: "Virtues",
    traits1Selected: "Unscathed Contender,Inspiring Virtue,Permeating Wrath",
    traits1Id: "46",
    traits1SelectedIds: "624,603,622"
  })))), React.createElement(GridItem, {
    sm: "5"
  }, React.createElement(Card, {
    title: "Situational Skills"
  }, React.createElement(Beginner, null, React.createElement(_components.p, null, "You should drop ", React.createElement(Skill, {
    name: "Whirling Light",
    id: "62565"
  }), " first."), React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9153",
    size: "big",
    disableText: true,
    id: "9153"
  })), React.createElement(_components.td, null, "When ", React.createElement(Boon, {
    name: "Stability"
  }), " or a stunbreak is needed.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9125",
    size: "big",
    disableText: true,
    id: "9125"
  })), React.createElement(_components.td, null, "Deals an additional 200 defiance bar damage.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9251",
    size: "big",
    disableText: true,
    id: "9251"
  })), React.createElement(_components.td, null, "A stationary reflect lasting for 10 seconds.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Sanctuary",
    size: "big",
    disableText: true,
    id: "9128"
  })), React.createElement(_components.td, null, "Huge CC and projectile destruction."))))), React.createElement(Advanced, null, React.createElement(_components.p, null, "If you need to use a different skill you can swap ", React.createElement(Skill, {
    name: "Whirling Light",
    id: "62565"
  }), " without losing much damage."), React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9246",
    size: "big",
    disableText: true,
    id: "9246"
  })), React.createElement(_components.td, null, "A 1,200 range teleport to an ally.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Hallowed Ground",
    size: "big",
    disableText: true,
    id: "9253"
  })), React.createElement(_components.td, null, "When ", React.createElement(Boon, {
    name: "Stability"
  }), " is needed. Also useful for precasting a fire field and ", React.createElement(Boon, {
    name: "Resolution"
  }), " at the mistlock.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Advance",
    size: "big",
    disableText: true,
    id: "9084"
  })), React.createElement(_components.td, null, "When extra ", React.createElement(Boon, {
    name: "Aegis"
  }), " is needed.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9153",
    size: "big",
    disableText: true,
    id: "9153"
  })), React.createElement(_components.td, null, "When ", React.createElement(Boon, {
    name: "Stability"
  }), " or a stunbreak is needed.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9125",
    size: "big",
    disableText: true,
    id: "9125"
  })), React.createElement(_components.td, null, "Deals an additional 200 defiance bar damage.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9251",
    size: "big",
    disableText: true,
    id: "9251"
  })), React.createElement(_components.td, null, "A stationary reflect lasting for 10 seconds.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9247",
    size: "big",
    disableText: true,
    id: "9247"
  })), React.createElement(_components.td, null, "A 1200 range teleport to an enemy.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Purging flames",
    size: "big",
    disableText: true,
    id: "9187"
  })), React.createElement(_components.td, null, "Cleanses conditions.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Sanctuary",
    size: "big",
    disableText: true,
    id: "9128"
  })), React.createElement(_components.td, null, "Huge CC and projectile destruction.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "renewed focus",
    size: "big",
    disableText: true,
    id: "9154"
  })), React.createElement(_components.td, null, "Recharges all virtue skills, grants ", React.createElement(Effect, {
    name: "Invulnerability"
  }), " for 3 seconds.")))))), React.createElement(Card, {
    title: "CC skills"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9093",
    id: "9093"
  })), React.createElement(_components.td, null, "300 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "9226",
    id: "9226"
  }), " (after ", React.createElement(Skill, {
    id: "9147",
    id: "9147"
  }), ")"), React.createElement(_components.td, null, "150 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "chainsoflight",
    id: "9099"
  })), React.createElement(_components.td, null, "250 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "hammerofwisdom",
    id: "9125"
  })), React.createElement(_components.td, null, "200 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "sanctuary",
    id: "9128"
  })), React.createElement(_components.td, null, "750 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Heavens Palm",
    id: "62561"
  })), React.createElement(_components.td, null, "400 damage (Knockdown) ", React.createElement("br"), " 150 damage (Knockback)"))))))), "\n", React.createElement(Advanced, null, React.createElement(Divider, {
    text: "Details"
  }), React.createElement(_components.p, null, "As a ", React.createElement(Specialization, {
    name: "Willbender",
    text: "Power Willbender"
  }), " it is important to play ", React.createElement(Trait, {
    name: "perfectinscriptions",
    id: "579"
  }), " to be able to use the ", React.createElement(Skill, {
    name: "Banesignet",
    id: "9093"
  }), " for 300 CC while also sharing the 216 ", React.createElement(Attribute, {
    name: "Power"
  }), " buff with your allies. This enables everyone to deal more damage during critical parts of the fight when the boss is ", React.createElement(Effect, {
    name: "exposed"
  }), "."), React.createElement(_components.p, null, "Generally it is important to apply ", React.createElement(Boon, {
    name: "Resolution"
  }), " when you are about to burst for the ", React.createElement(Trait, {
    name: "retribution",
    id: "565"
  }), " modifier. Thanks to ", React.createElement(Trait, {
    name: "healersresolution",
    id: "574"
  }), " your heal skill applies long lasting ", React.createElement(Trait, {
    name: "retribution",
    id: "565"
  }), ". Since ", React.createElement(Skill, {
    name: "litanyofwrath",
    id: "21664"
  }), " has a low cooldown it lines up well with your burst. You can also trigger a ", React.createElement(Skill, {
    id: "13677",
    id: "13677"
  }), " by losing health below the 75% threshold to trigger ", React.createElement(Trait, {
    id: "648",
    id: "648"
  }), ", however you need to be careful to not die.")), "\n", React.createElement(Divider, {
    text: "Rotation / Skill Usage"
  }), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    xs: "12",
    sm: "7"
  }, React.createElement(Advanced, null, React.createElement(Card, {
    title: "Information"
  }, React.createElement(_components.p, null, "Golem rotations out of the raid builds are generally suboptimal in fractals due to ", React.createElement(Effect, {
    name: "Exposed"
  }), " and phases being much shorter compared to raids. The raid rotations are optimized for sustained DPS while in fractals a player needs the ability to adapt their rotation to the amount of time a group needs to finish a phase.", React.createElement(_components.br), "\n", "For that reason, you will find some general notes for skill usage here.")), React.createElement(Card, {
    title: "Precasting"
  }, React.createElement(_components.p, null, "If you have a Mistlock Singularity present you can use these skills for precasts:"), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "litany of wrath",
    id: "21664"
  }), " for ", React.createElement(Boon, {
    name: "Resolution"
  })), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Save yourselves",
    id: "9085"
  }), " or ", React.createElement(Skill, {
    name: "hallowedground",
    id: "9253"
  })), "\n", React.createElement(_components.li, null, "Blast a fire field with ", React.createElement(Skill, {
    name: "Holy Strike",
    id: "9140"
  })), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Feelmywrath",
    id: "29965"
  })), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "banesignet",
    id: "9093"
  })), "\n", React.createElement(_components.li, null, "Take mistlock"), "\n", React.createElement(_components.li, null, "Blast a fire field with ", React.createElement(Skill, {
    name: "Holy Strike",
    id: "9140"
  }), " again"), "\n")), React.createElement(Card, {
    title: "Notes on skill usage:"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Always start on sword"), "\n", React.createElement(_components.li, null, "Delay swapping to GS until the CC-bar is about to be broken. This is especially important at Ensolyss without instant CC."), "\n", React.createElement(_components.li, null, "Always cast your ", React.createElement(Skill, {
    name: "whirlingwrath",
    id: "9081"
  }), " inside the hitbox to get the additional hits. Ideal with adds around."), "\n", React.createElement(_components.li, null, "Always cast your ", React.createElement(Skill, {
    name: "bindingblade",
    id: "9147"
  }), " inside the hitbox to get the additional hits when adds are present. Can be abused at ", React.createElement(_components.strong, null, "Artsariiv"), ", ", React.createElement(_components.strong, null, "Arkk"), ", ", React.createElement(_components.strong, null, "Siax"), ", and ", React.createElement(_components.strong, null, "Ensolyss"), "."), "\n", React.createElement(_components.li, null, "Don't interrupt your GS auto-attack chain."), "\n", React.createElement(_components.li, null, "In certain scenarios (precasting symbols, starting with ", React.createElement(Skill, {
    name: "onewolfpack",
    id: "45717"
  }), ") it can be beneficial to get your ", React.createElement(Skill, {
    name: "Rushing Justice",
    id: "62668"
  }), " out first."), "\n", React.createElement(_components.li, null, "Don't waste your key skills on meaningless adds."), "\n"))), React.createElement(Beginner, null, React.createElement(Information, null, React.createElement(_components.p, null, "This build plays very similar to the ", React.createElement(Specialization, {
    text: "Power Dragonhunter",
    name: "Dragonhunter"
  }), " and therefore the rotation, as well as the step-by-step explanation, will be very similar.")), React.createElement(Card, {
    title: "Step-by-Step Rotation"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Step 0: Bane Signet")), React.createElement(_components.p, null, "The ", React.createElement(Skill, {
    name: "Bane Signet",
    id: "9093"
  }), " grants you a passive boost to your ", React.createElement(Attribute, {
    name: "Power"
  }), ". Its active effect (when clicked) will inflict a high amount of CC (300 CC) on your target and share the passive with your allies for 10 seconds through ", React.createElement(Trait, {
    name: "Perfect Inscriptions",
    id: "579"
  }), ". Therefore, you want to do the following:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If a Mistlock Singularity is present, cast ", React.createElement(Skill, {
    name: "Bane Signet",
    id: "9093"
  }), " there to share its buff right before you run into the fight, then take the singularity."), "\n", React.createElement(_components.li, null, "If a boss starts with a break bar, you should cast ", React.createElement(Skill, {
    name: "Bane Signet",
    id: "9093"
  }), " at the start to help with breaking the bar while also sharing its passive to your allies to enhance their burst damage as well."), "\n", React.createElement(_components.li, null, "If a boss does not get a break bar early into the fight, you can use ", React.createElement(Skill, {
    name: "Bane Signet",
    id: "9093"
  }), " before the fight starts to share the passive and start right with your burst when the boss spawns."), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Step 1: Greatsword Skills")), React.createElement(_components.p, null, "The first step consists of using ", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), ", ", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), " and ", React.createElement(Skill, {
    name: "Binding Blade",
    id: "9147"
  }), " off-cooldown (in this order of priority if multiple come up at the same time)."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), " and ", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), " are your 2 biggest damaging weapon skills on the Greatsword."), "\n", React.createElement(_components.li, null, "Make sure to be in the hitbox of the enemy whenever possible for ", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), ". ", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), " deals additional damage when casted inside a hitbox."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Binding Blade",
    id: "9147"
  }), " will deal more damage if you are in the hitbox while there are extra enemies around."), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Step 2: Virtue Skills and Whirling Light")), React.createElement(_components.p, null, "Next up, it is time to incorporate ", React.createElement(Skill, {
    name: "Rushing Justice",
    id: "62668"
  }), " and ", React.createElement(Skill, {
    name: "Whirling Light",
    id: "62565"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Using ", React.createElement(Skill, {
    name: "Rushing Justice",
    id: "62668"
  }), " will create ", React.createElement(Skill, {
    name: "Willbender Flames",
    id: "62528"
  }), " and makes you inflict ", React.createElement(Condition, {
    name: "Burning"
  }), " every few hits. This will grant you a stacking buff from ", React.createElement(Trait, {
    name: "Lethal Tempo",
    id: "2189"
  }), ". You want to use this skill as soon as possible to start stacking the buff."), "\n", React.createElement(_components.li, null, "If you precast another Virtue before the fight, you can delay this skill a bit."), "\n", React.createElement(_components.li, null, "Whenever you need healing or ", React.createElement(Boon, {
    name: "Aegis"
  }), "/", React.createElement(Boon, {
    name: "Stability"
  }), ", you can use ", React.createElement(Skill, {
    name: "Flowing Resolve",
    id: "62603"
  }), " or ", React.createElement(Skill, {
    name: "Crashing Courage",
    id: "62532"
  }), " instead."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Light",
    id: "62565"
  }), " deals high damage and can safely be used off-cooldown. It has a lower priority than most of your other skills."), "\n", React.createElement(_components.li, null, "If you kill certain enemies, your ", React.createElement(Skill, {
    name: "Rushing Justice",
    id: "62668"
  }), " will reset due to ", React.createElement(Trait, {
    name: "Renewed Justice",
    id: "571"
  }), ". In this case, your other skills will still take priority until the current ", React.createElement(Skill, {
    name: "Rushing Justice",
    id: "62668"
  }), " runs out.\nThe opener at this step looks like this:"), "\n"), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Rushing Justice",
    id: "62668"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Binding Blade",
    id: "9147"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Light",
    id: "62565"
  }), "\n"), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Step 3: Sword of Justice")), React.createElement(_components.p, null, "Now it is time to incorporate another strong utility skill, ", React.createElement(Skill, {
    name: "Sword of Justice",
    id: "9168"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Sword of Justice",
    id: "9168"
  }), " has 3 maximum charges. It summons a sword that will deal damage and inflict ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " on nearby enemies while it is active."), "\n", React.createElement(_components.li, null, "Make sure to use one charge right at the start to help ramp up ", React.createElement(Condition, {
    name: "Vulnerability"
  }), "."), "\n", React.createElement(_components.li, null, "Afterwards, you can freely use this skill at any point of the rotation."), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Step 4: Sword skills")), React.createElement(_components.p, null, "This last step will not contribute much to your consistent DPS but can increase your burst by a good amount which is especially important in fights with shorter phases. We will use ", React.createElement(Skill, {
    name: "Symbol of Blades",
    id: "9097"
  }), " and ", React.createElement(Skill, {
    name: "Zealots Defense",
    id: "9107"
  }), " at the start of the fight, then swap to Greatsword and do our regular rotation."), React.createElement(_components.p, null, "The opener should now look like this:"), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sword of Justice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Rushing Justice",
    id: "62668"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Blades",
    id: "9097"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Zealots Defense",
    id: "9107"
  }), "\n"), "\n", React.createElement(_components.li, null, "Swap to Greatsword"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Binding Blade",
    id: "9147"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Light",
    id: "62565"
  }), "\n"), "\n")), React.createElement(Card, {
    title: "Improving Further"
  }, React.createElement(_components.p, null, "Once you are comfortable with the above steps, you are already doing most of the full rotation and will be able to deal great burst damage and good consistent damage."), React.createElement(_components.p, null, "There will be a few additional things on the advanced page to improve further but if you got here, you already know the most important things and these will have a smaller impact than what you learned so far."), React.createElement(_components.p, null, "The additional steps are:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Incorporate your Focus skills into the rotation, namely ", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), " and ", React.createElement(Skill, {
    name: "Shield of Wrath",
    id: "9082"
  }), "."), "\n", React.createElement(_components.li, null, "Weapon swap more often on fights with long phases, usually after using ", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), "/", React.createElement(Skill, {
    name: "Zealots Defense",
    id: "9107"
  }), " twice on the respective weapon set."), "\n", React.createElement(_components.li, null, "Precasts: You can cast lasting skills such as ", React.createElement(Skill, {
    name: "Symbol of Blades",
    id: "9097"
  }), " before the fight starts to get your damage up earlier and have 1 less cast in your opener."), "\n")))), React.createElement(GridItem, {
    xs: "12",
    sm: "5"
  }, React.createElement(Advanced, null, React.createElement(Card, {
    title: "Opener"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Rotation:")), React.createElement(_components.p, null, "This is just a suggested opener. Depending on the encounter and the length of the phase you will want to start on different weapons and use different skills. ", React.createElement(Specialization, {
    name: "Willbender",
    text: "Power Willbender"
  }), " plays very similarly to ", React.createElement(Specialization, {
    name: "Dragonhunter",
    text: "Power Dragonhunter"
  }), ", if you need some ideas you can use the ", React.createElement(_components.a, {
    href: "/cm-guides/guardian/power-dragonhunter/static/"
  }, "Dragonhunter CM Guide"), "."), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "banesignet",
    profession: "guardian",
    id: "9093"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Symbol of Blades",
    profession: "guardian",
    id: "9097"
  }), " (Sword 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Shield of Wrath",
    profession: "guardian",
    id: "9082"
  }), " (Focus 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sword of Justice",
    profession: "guardian",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Ray of Judgment",
    profession: "guardian",
    id: "9112"
  }), " (Focus 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Rushing Justice",
    profession: "guardian",
    id: "62668"
  }), " (F1)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Zealotsdefense",
    profession: "guardian",
    id: "9107"
  }), " (Sword 3)"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Symbol of Resolution",
    profession: "guardian",
    id: "9146"
  }), " (GS 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Wrath",
    profession: "guardian",
    id: "9081"
  }), " (GS 2)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Light",
    profession: "guardian",
    id: "62565"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sword of Justice",
    profession: "guardian",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sword of Justice",
    profession: "guardian",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Leap of Faith",
    profession: "guardian",
    id: "9080"
  }), " (GS 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Binding Blade",
    profession: "guardian",
    id: "9147"
  }), " (GS 5)"), "\n", React.createElement(_components.li, null, "If the phase lasts longer than this, continue with the ", React.createElement(_components.a, {
    href: "https://snowcrows.com/builds/guardian/willbender/power-willbender"
  }, "SC rotation"), "."), "\n"))), React.createElement(Beginner, null, React.createElement(Card, {
    title: "Step-by-Step Video"
  }, React.createElement(_components.p, null, "This video shows the step-by-step approach to the rotation listed on the left."), React.createElement(Video, {
    youtube: "viygzTwDJek",
    caption: "by Ascers"
  }))), React.createElement(Card, {
    title: "Golem Rotation"
  }, React.createElement(_components.p, null, "This video shows the full rotation on the golem. It will slightly vary from the step-by-step version. It also uses the raid setup so numbers will not be comparable."), React.createElement(Video, {
    youtube: "-OiH1BLuJpo",
    caption: "by Nagy"
  })))), "\n", React.createElement(Divider, {
    text: "Underwater combat"
  }), "\n", React.createElement(_components.p, null, "It is very much recommended to play ", React.createElement(BuildLink, {
    build: "Condi Firebrand",
    specialization: "Firebrand"
  }), " underwater since it provides much better DPS compared to the ", React.createElement(Specialization, {
    text: "Power Willbender",
    name: "Willbender"
  }), ". If you still want to play ", React.createElement(Specialization, {
    text: "Power Willbender",
    name: "Willbender"
  }), ", follow these rough guidelines."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Open with ", React.createElement(Skill, {
    name: "refraction",
    id: "9209"
  }), " for ", React.createElement(Boon, {
    name: "resolution"
  })), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "purify",
    id: "9207"
  }), " and swap to spear. Camp spear now."), "\n", React.createElement(_components.li, null, "Priority list:", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Zealots flurry",
    id: "9190"
  }), " (Spear 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Symbol of spears",
    id: "9192"
  }), " (Spear 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "brilliance",
    id: "9191"
  }), " (Spear 3)"), "\n"), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
